import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MDReactComponent from "markdown-react-js"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import SendIcon from "@material-ui/icons/Send"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import EventIcon from "@material-ui/icons/Event"
import Paper from "@material-ui/core/Paper"

// @Local
import { getDateFormat } from "../utils/date"
import { LikeButton, FavButton } from "../components/actions/Actions"

const TemplateEvent = ({ data }) => {
  const classes = useStyles()

  if (!data) {
    return (
      <Paper>
        <h2>...</h2>
      </Paper>
    )
  }

  let {
    title,
    content,
    image,
    strapiId,
    date_final,
    date_init,
    place,
  } = data.strapiCalitem

  return (
    <Layout section="Eventos" sectionLink={"/app/eventos"}>
      <SEO title={title} />
      <Grid container spacing={3} className="page-detail event-detail">
        <Grid item xs={12} className="page-detail-content">
          <Typography
            className="page-title"
            variant="h1"
            color="textPrimary"
            align="center"
          >
            {title}
          </Typography>

          <Card className={classes.root}>
            <CardMedia
              className={classes.cover}
              image={image.publicURL}
              title={title}
            />
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <div className="page-place">
                  {place && (
                    <Tooltip
                      title="Ubicación o lugar principal de trabajo"
                      placement="left"
                      arrow
                    >
                      <ListItem>
                        <ListItemIcon>
                          <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary={place} />
                      </ListItem>
                    </Tooltip>
                  )}
                </div>
                <div className="closed-date">
                  {date_init && (
                    <Tooltip title="Fecha de inicio" placement="left" arrow>
                      <ListItem>
                        <ListItemIcon>
                          <EventIcon className={classes.greenIcon} />
                        </ListItemIcon>
                        <Typography className={classes.textIcon}>
                          {getDateFormat(date_init)}
                        </Typography>
                      </ListItem>
                    </Tooltip>
                  )}
                </div>
                <div className="closed-date">
                  {date_final && (
                    <Tooltip title="Fecha de cierre" placement="left" arrow>
                      <ListItem>
                        <ListItemIcon>
                          <EventIcon className={classes.redIcon} />
                        </ListItemIcon>
                        <Typography className={classes.textIcon}>
                          {getDateFormat(date_final)}
                        </Typography>
                      </ListItem>
                    </Tooltip>
                  )}
                </div>
                <div className="page-markdown">
                  {content && <MDReactComponent text={content} />}
                </div>
              </CardContent>
              <div className={classes.controls}>
                <div className="page-actions">
                  <LikeButton
                    strapiId={strapiId}
                    type={"Event"}
                    title={title}
                  />
                  <FavButton strapiId={strapiId} type={"Event"} title={title} />
                </div>
                <div className="wrapper-apply-job">
                  <Button
                    variant="contained"
                    color="primary"
                    className={"btnApplyJob"}
                    endIcon={<SendIcon />}
                  >
                    Me interesa
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query TemplateEventQuery($id: String!) {
    strapiCalitem(id: { eq: $id }) {
      id
      strapiId
      title
      content
      date_final
      date_init
      place
      image {
        publicURL
        prettySize
      }
      fields {
        slug
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "40%",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  textIcon: {
    fontWeight: 500,
    fontSize: 18,
  },
  greenIcon: {
    fontSize: 30,
    color: "#61bf61",
  },
  redIcon: {
    fontSize: 30,
    color: "#ff8e8e",
  },
  btnApplyJob: {},
}))

export default TemplateEvent
